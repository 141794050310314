<template>
  <div v-if="audits.length > 0">
      <hr/>
      <h3>Audit</h3>
        <b-table
          striped
          hover
          sort-icon-left          
          :items="audits"
          :fields="auditFields"
        >
          <template #cell(created_at)="data">
            {{showDate(data.item.created_at)}}
          </template>        
        </b-table>
  </div>
</template>



<script>

  import ApiService from "@/core/services/api.service";
  import moment from "moment";

  export default {
    data() {
      return {
        audits: [],
        auditFields: [
            { key: "created_at", label: "When" , sortable: true},
            { key: "user", label: "By Who", sortable: true },
            { key: "audit", label: "Action", sortable: true  },
        ],        
      }
    },
    props: {
        id: String,
        action: String,
    },
    mounted() {
        this.getAudits();
    },
    methods: {

        showDate(date) {
            return moment(date).format('L HH:mm')
        },

        getAudits() {

            var data = {
                related_id: this.id,
                related_action: this.action,
            }

            console.log('data',data);

            ApiService.setHeader();
            ApiService.post("api/audit", data)
                .then(({ data }) => {
                    console.log(data);
                    this.audits= data.data.audits;
                })
                .catch(() => {
                    this.audits= [];
                });            

        },    

    },
    watch: {
        id: function() {
            this.getAudits();
        },
    },    
  }
</script>