<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit Risk
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create Risk
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes_inner"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Reference</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="reference"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userRecord.reference.$model"
            />
            <div class="error" v-if="!$v.userRecord.reference.required">
              <i>Reference is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Name</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userRecord.name.$model"
            />
            <div class="error" v-if="!$v.userRecord.name.required">
              <i>Name is required</i>
            </div>
          </div>
        </div>        
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Description</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="description"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userRecord.description.$model"
            />
            <div class="error" v-if="!$v.userRecord.description.required">
              <i>Description is required</i>
            </div>
          </div>
        </div>        

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Risk Matrix</label
          >
          <div class="col-lg-9 col-xl-6">
            <center><b><i>Please click on the matrix below to select you Probablity and Impact.<br/><br/></i></b></center>

          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-2 col-lg-2 col-form-label text-right"
            ></label
          >          
          <div class="col-lg-8 col-xl-8">
            <RiskMatrix 
              :likelyhood="userRecord.probability" 
              :impact="userRecord.impact" 
              :risk="userRecord.risk" 
              :riskreview="currentUser.riskreview"
              @riskSelected="riskSelected"
              />        
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Treatment Plan</label
          >
          <div class="col-lg-9 col-xl-6">
            <vue-editor ref="treat" v-model="userRecord.treat" />        
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Tolerate Plan</label
          >
          <div class="col-lg-9 col-xl-6">
            <vue-editor ref="tolerate" v-model="userRecord.tolerate" />        
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Transfer Plan</label
          >
          <div class="col-lg-9 col-xl-6">
            <vue-editor ref="transfer" v-model="userRecord.transfer" />        
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Terminate Plan</label
          >
          <div class="col-lg-9 col-xl-6">
            <vue-editor ref="terminate" v-model="userRecord.terminate" />        
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Risk Owner</label
          >
          <div class="col-lg-9 col-xl-6">
            <b-form-select
                ref="risk_owner"
                v-model="userRecord.risk_owner"
                :options="getUserData"
            ></b-form-select>
            <div class="error" v-if="!$v.userRecord.risk_owner.required">
              <i>Risk Owner is required</i>
            </div>                         
          </div>
        </div>       


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >System / Asset</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect 
                v-model="userRecord.systems" ref="systems" :multiple="true" deselect-label="No Systems" track-by="id" label="name" placeholder="Select one" :options="getSystemData" :searchable="true" :allow-empty="false">
            </multiselect>
                       
          </div>
        </div>               

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Linked control</label
          >
          <div class="col-lg-9 col-xl-6">
            <multiselect 
                v-model="userRecord.frameworks" ref="frameworks" :multiple="true" deselect-label="No Controls" track-by="id" label="name" placeholder="Select one" :options="frameworkItems" :searchable="true" :allow-empty="false">
            </multiselect>
                       
          </div>
        </div>               

                              

      </div>
    </form>
    <!--end::Form-->


    <audit-notes :id="userRecord.id" action="Risk"/>

  </div>
</template>

<script>

import { mapGetters } from "vuex";
import { UPDATE_THREAT_DETAILS, CREATE_THREAT_DETAILS } from "@/core/services/store/threats.module";


import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import RiskMatrix from "@/view/pages/work/threats/RiskMatrix";
import AuditNotes from "@/view/content/sections/AuditNotes";


export default {
  components: {RiskMatrix,AuditNotes},
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  data() {
    return {
      audits: [],
      userRecord: this.record,
    };
  },
  validations: {
    userRecord: {
      name: {
        required,
      },
      description: {
        required,
      },
      risk_owner: {
        required,
      },
      reference: {
        required
      }
    },
  },
  mounted() {


  },
  methods: {

    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var id = this.userRecord.id;        
        var reference = this.$refs.reference.value;;        
        var name = this.$refs.name.value;
        var description = this.$refs.description.value;
        var probability = this.userRecord.probability;
        var impact = this.userRecord.impact;
        var risk = this.userRecord.risk;
        var treat = this.userRecord.treat;
        var tolerate = this.userRecord.tolerate;
        var transfer = this.userRecord.transfer;
        var terminate = this.userRecord.terminate;
        var risk_owner = this.$refs.risk_owner.value;
        var systems = this.$refs.systems.value;
        var frameworks = this.$refs.frameworks.value;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes_inner"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_THREAT_DETAILS, {
              id,
              reference,
              name,
              description,
              probability,
              impact,
              risk,
              treat,
              tolerate,
              transfer,
              terminate,
              risk_owner,
              systems,
              frameworks
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request
          this.$store
            .dispatch(UPDATE_THREAT_DETAILS, {
              id,
              reference,
              name,
              description,
              probability,
              impact,
              risk,
              treat,
              tolerate,
              transfer,
              terminate,
              risk_owner,
              systems,
              frameworks
            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
    riskSelected(values) {
      this.userRecord.probability = values.likelyhood;
      this.userRecord.impact = values.impact;
      this.userRecord.risk = values.risk;
    },    
  },
  computed: {
    ...mapGetters(["currentUser","currentUsersData","currentSystemsData","currentFramework","currentFRAMEWORKACTIONsData"]),
    getUserData() {
      var users = [];
      if (this.currentUsersData.users) {
        this.currentUsersData.users.forEach(user => {
          users.push({value:user.id,text:user.name})
          
        });
      }
      return users
    },
    getSystemData() {
      var systems = [];
      console.log('systems',this.currentSystemsData)
      if (this.currentSystemsData) {
        console.log(this.currentSystemsData)
        this.currentSystemsData.forEach(system => {
          systems.push({id:system.id,name: (system.name + "(" + system.category + ")") })
          
        });
      }
      return systems
    },
    frameworkItems() {
      var results = [];
     
      if (this.currentFramework.framework_items) {   
        this.currentFramework.framework_items.forEach((item) => {

          results.push({
            id: item.id,
            name: item.objective_code + ' - ' + item.objective,
          });
        });
      }
      return results;
    },    
    
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
