<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
        >
          Select Preset Threats 
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button class="btn btn-success mr-2" @click="selectAllRows">Select all</b-button>
        <b-button class="btn btn-success mr-2" @click="clearSelected">Clear selected</b-button>          
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes_inner"
          v-if="selected.length > 0"
        >
          Add the {{selected.length}} Selected Threats
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Form-->
        <b-table
          striped
          hover
          sort-icon-left   
          selectable 
          select-mode="multi"
          :items="presets"
          :fields="presetFields"
          ref="presetTable"
          @row-selected="onRowSelected"
        >
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
                </template>
            </template>  
          <template #cell(name)="data">
            {{data.item.name}}<span v-if="alreadySelected(data.item) != ''"> (<b>{{alreadySelected(data.item)}}</b>)</span>
          </template>                  
        </b-table>

  </div>
</template>

<script>

import { CREATE_PRESETS } from "@/core/services/store/threats.module";


export default {
  name: "ShowPresetInformation",
  props: {
    threats: Object,
    presets: Object,
  },
  data() {
    return {
      presetFields: [
        { key: 'selected', label: "Selected" },
        { key: "name", label: "Name", sortable: true },
        { key: "description", sortable: true  },
      ],
      selected: []
    };
  },
  mounted() {
  },
  methods: {
    save() {

        this.$store
        .dispatch(CREATE_PRESETS, {
            selected: this.selected
        })
        .then(() => {
            this.$emit("finished");
        });

    },
    alreadySelected(item) {
      var retVal = '';
      var targetListResult = this.threats.filter(function(o) {
        return o.name == item.name;
      });
      if (targetListResult.length > 0) {
        retVal = "Already Selected"
      }
      return retVal;
    },
    cancel() {
      this.$emit("canceled");
    },
    onRowSelected(items) {
        this.selected = items
    },  
    selectAllRows() {
        this.$refs.presetTable.selectAllRows()
    },
    clearSelected() {
        this.$refs.presetTable.clearSelected()
    },      
  },
  computed: {
  },
};
</script>
